// Orders services
import { BaseOrder, Catalogue, Country, Order } from "artisn/types";
import { OrderDetails, ShoppingCart, Vendor } from "artisn/types";

import axiosDefault from "utils/axios.utils";
import { buildHeaders } from "utils/services.utils";
import { PostAnonymousOrderPayload } from "./orders.service.types";
import { ValidatePayment } from "./orders.service.types";
import { PostOrderPayload } from "./orders.service.types";
import { PostRetryOrderPayload } from "./orders.service.types";
import { notify, shouldMock } from "utils/common.utils";
import { IncompleteOrder } from "types/order.types";
import { mockPostOrder } from "./orders.service.mock";
import { ErrorResponse } from "types/common.types";

const baseUrl = "v2/order";

/**
 * Creates a new order.
 *
 * @param {PostOrderPayload} orderPayload The data needed to create an order
 * @returns {OrderDetails} The newly created order
 */
export const postOrder = async (
  orderPayload: PostOrderPayload | PostAnonymousOrderPayload
): Promise<OrderDetails> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.post(
        baseUrl,
        { ...orderPayload },
        {
          headers: await buildHeaders()
        }
      );
      return data.data;
    } else {
      const { mockPostOrder } = await import("./orders.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          // @ts-ignore TODO: fix when the order payload is definitive
          resolve(mockPostOrder(orderPayload));
        }, 1000);
      });
    }
  } catch (e) {
    const { message = e.message } = e.response?.data ?? {};
    throw new Error(message);
  }
};

/**
 * Fetches the given order details.
 *
 * @param {number} orderId Order id of the wanted order
 * @returns {OrderDetails} The order details object of the given id
 */
export const fetchOrderDetails = async (
  orderId: Order["id"]
): Promise<OrderDetails> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(`${baseUrl}/${orderId}`, {
        headers: await buildHeaders()
      });
      return data.data;
    } else {
      const { mockOrderDetails } = await import("./orders.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockOrderDetails(orderId));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches a list of stored order history of the signed in user.
 *
 * @param {string} ordersCategory Filters the list by the order category
 * @returns {BaseOrder[]} A list of orders of the signed in user
 */
export const fetchOrderHistory = async (
  vendorId: Vendor["id"],
  catalogueId?: Catalogue["catalogueId"],
  ordersCategory?: "IN_PROGRESS" | "DONE"
): Promise<BaseOrder[]> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(`${baseUrl}/history`, {
        headers: await buildHeaders(),
        params: {
          channel_id: catalogueId,
          category: ordersCategory
        }
      });

      return data.data.data;
    } else {
      const { mockOrderHistory } = await import("./orders.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockOrderHistory());
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Cancels a given order of the signed in user.
 *
 * @param {number} orderId The id of the order
 * @returns {OrderDetails} The canceled order details
 */
export const putCancelOrder = async (
  orderId: Order["id"]
): Promise<OrderDetails> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.put(
        `${baseUrl}/${orderId}/cancel`,
        { toStep: "CANCELED" },
        {
          headers: await buildHeaders()
        }
      );

      return data;
    } else {
      const { mockOrderDetails } = await import("./orders.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockOrderDetails(orderId));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Creates a new order retry for the signed in user.
 *
 * @param {PostRetryOrderPayload} retryOrderPayload Order data needed to retry a place order
 */
export const postRetryOrder = async (
  retryOrderPayload: PostRetryOrderPayload
): Promise<OrderDetails> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.post(
        "api/order/payment-retry",
        retryOrderPayload,
        {
          headers: await buildHeaders()
        }
      );
      return data.data;
    } else {
      return new Promise<OrderDetails>((resolve, reject) => {
        setTimeout(() => {
          resolve(mockPostOrder(retryOrderPayload));
        }, 1000);
      });
    }
  } catch (e) {
    if (e.response?.data) {
      const response: ErrorResponse = e.response.data;
      const message = response.warning?.[0].value;
      throw new Error(message);
    } else {
      throw new Error(e.message);
    }
  }
};

/**
 * Repeat an order whether or not you already have a cart created.
 *
 * @param {number} orderId The order's unique identifier that the user wants to
 * repeat
 * @param {number} shoppingCartId The current shopping cart's unique identifier
 */
export const fetchRebuildCart = async (
  orderId: Order["id"],
  shoppingCartId?: ShoppingCart["id"]
): Promise<void> => {
  try {
    if (!shouldMock) {
      let url = `api/order/rebuild-cart/${orderId}?action=REPLACE`;
      if (shoppingCartId) url += `&currentShoppingCartId=${shoppingCartId}`;
      await axiosDefault.get(url, {
        headers: await buildHeaders()
      });
    } else {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    }
  } catch (e) {
    const { message = e.message } = e.response?.data ?? {};
    throw new Error(message);
  }
};

/**
 * Fetches a list of pending orders.
 *
 * @param {string} catalogueId Filters the orders by catalogue identifier
 */
export const fetchIncompleteOrders = async (
  catalogueId: Catalogue["catalogueId"],
  vendorId: Vendor["id"]
): Promise<IncompleteOrder[]> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(`api/order/pending`, {
        headers: await buildHeaders(),
        params: {
          status_payment: "PENDING",
          vendor_id: vendorId,
          channel_id: catalogueId
        }
      });

      return data.data;
    } else {
      const { mockIncompleteOrders } = await import("./orders.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockIncompleteOrders());
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Migrates the anonymous orders to a registered user
 *
 * @param {number} countryId The country's unique identifier
 * @param {string} uid The anonymous identifier (uid) of the registered user
 */
export const postMigrateAnonymousOrders = async (
  countryId: Country["id"],
  uid: string | undefined
): Promise<void> => {
  try {
    if (!uid) throw new Error("uid is missing");
    if (!shouldMock) {
      await axiosDefault.post(
        `api/order/update-user-orders/${uid}`,
        {
          countryId
        },
        {
          headers: await buildHeaders()
        }
      );
    } else {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * The payment is validated again
 *
 * @param {number} orderId The order identifier
 */
export const fetchValidatePayment = async (
  orderId: number
): Promise<ValidatePayment | ValidatePayment[]> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(
        `/api/order/${orderId}/validatePayment`,
        {
          headers: await buildHeaders()
        }
      );

      return data.data;
    } else {
      const { mockValidatePayment } = await import("./orders.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockValidatePayment());
        }, 1000);
      });
    }
  } catch (e) {
    notify(e, "Get validate payment request");
    throw new Error(e.message);
  }
};
