// Payments builder functions
import { PaymentMethod } from "artisn/types";
import { paymentBuilders, utils } from "artisn/builders";

import { KushkiCard, PayPhoneCard, StatusText } from "types/payment.types";
import CONSTANTS from "config/constants";
import { ValidatePayment } from "services/orders/orders.service.types";

const { genNumericId, genWord, genNumber, genId, chooseRandom } = utils;
const { genItems } = utils;
const { buildPaymentMethod, buildPaymentProvider } = paymentBuilders;
const { PAYMENT_METHODS, PAYMENT_PROVIDERS } = CONSTANTS.ARTISN;
const { KUSHKI_ID, PAY_PHONE_ID } = PAYMENT_PROVIDERS;
const { CARD_ID, CASH_ID } = PAYMENT_METHODS;

export const genPaymentMethods = (quantity?: number): PaymentMethod[] => {
  const num = quantity ?? Math.floor(Math.random() * 1) + 1;

  const methods = [
    buildPaymentMethod({
      id: CASH_ID,
      methods: []
    }),
    buildPaymentMethod({
      id: CARD_ID,
      methods: [
        buildPaymentProvider({
          id: KUSHKI_ID,
          image_url: "/assets/images/kushki.svg",
          cc_brands: [],
          name: "Kushki"
        }),
        buildPaymentProvider({
          id: PAY_PHONE_ID,
          cc_brands: [],
          name: "PayPhone"
        })
      ]
    })
  ];

  if (num === 0) {
    return [];
  }
  if (num === 1) {
    return [chooseRandom(methods)];
  }
  return methods;
};

export const buildKushkiCard = (
  overrides: Partial<KushkiCard> = {}
): KushkiCard => {
  return {
    authCode: genNumericId().toString(),
    bin: genWord(),
    createdAt: new Date().toDateString(),
    id: chooseRandom([CARD_ID, CASH_ID]),
    uid: genId(),
    number: genNumericId().toString(),
    paymentToken: genNumericId().toString(),
    type: genWord(),
    paymentMethodId: genNumber(),
    ...overrides
  };
};

export const genCards = (quantity?: number) =>
  genItems(buildKushkiCard, quantity);

export const buildPayPhoneCard = (
  overrides: Partial<PayPhoneCard> = {}
): PayPhoneCard => {
  return {
    createdAt: new Date().toDateString(),
    id: chooseRandom([CARD_ID, CASH_ID]),
    uid: genId(),
    number: genNumericId().toString(),
    token: genNumericId().toString(),
    type: genWord(),
    paymentMethodId: genNumber(),
    ...overrides
  };
};

export const genPayPhoneCardCards = (quantity?: number) =>
  genItems(buildPayPhoneCard, quantity);

export const buildOrderValidatePayment = (
  overrides: Partial<ValidatePayment> = {}
): ValidatePayment => {
  const status: StatusText[] = ["APPROVED", "PENDING", "REJECTED"];
  return {
    date: new Date().toDateString(),
    message: genWord(),
    reason: null,
    status: chooseRandom(status),
    ...overrides
  };
};

export const genOrderValidatePayment = (quantity?: number) =>
  genItems(buildOrderValidatePayment, quantity);
