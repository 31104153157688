// Orders services
import { OrderDetails } from "artisn/types";
import { orderBuilders } from "artisn/builders";

import { genIncompleteOrders } from "utils/testUtils/builders/order.builder";
import CONSTANTS from "config/constants";
import { buildOrderValidatePayment } from "utils/testUtils/builders/payments.builder";

const { MOCK_WITH_INITIAL_USER_DATA } = CONSTANTS.API;

const { buildOrderDetails, buildBaseOrder } = orderBuilders;
const { buildOrderPaymentMethod, buildOrderProduct } = orderBuilders;

const ordersCreated: Partial<OrderDetails>[] = [];

export const mockOrderDetails = (orderId: number): OrderDetails => {
  const orderDetails = buildOrderDetails();
  const paidPaymentMethod = buildOrderPaymentMethod({
    payment_method_name: "Tarjeta de crédito"
  });

  return {
    ...orderDetails,
    id: orderId,
    stepName: "Pago aprobado",
    step: {
      ...orderDetails.step,
      title_frontend: "Contactando al local",
      description_frontend:
        "Estamos asignando tu orden al local correspondiente."
    },
    shippingAddress: {
      ...orderDetails.shippingAddress,
      reference: "Oficina: Av. República del salvador"
    },
    shoppingCart: {
      ...orderDetails.shoppingCart,
      stores: [
        {
          ...orderDetails.shoppingCart.stores[0],
          storeName: "Urban Power",
          products: [
            buildOrderProduct(),
            buildOrderProduct(),
            buildOrderProduct(),
            buildOrderProduct(),
            buildOrderProduct(),
            buildOrderProduct(),
            buildOrderProduct(),
            buildOrderProduct()
          ]
        }
      ],
      paidPaymentMethods: [paidPaymentMethod]
    },
    billingData: { ...orderDetails.billingData, address: "Oficina" },
    ...(ordersCreated.find(order => order.id === orderId) ?? {})
  };
};

export const mockPostOrder = (order: Partial<OrderDetails>) => {
  ordersCreated.push(order);
  return buildOrderDetails(order);
};

export const mockOrderHistory = () => {
  if (!MOCK_WITH_INITIAL_USER_DATA) {
    return ordersCreated.map(order => {
      return buildBaseOrder(order);
    });
  }
  return [
    buildBaseOrder({
      stepCategory: "IN_PROGRESS",
      seq_id: "20382726",
      stepName: "Procesando pago"
    }),
    buildBaseOrder({
      stepCategory: "DONE"
    }),
    buildBaseOrder({
      stepCategory: "IN_PROGRESS"
    })
  ];
};

export const mockIncompleteOrders = () => genIncompleteOrders();

export const mockValidatePayment = () => buildOrderValidatePayment();
